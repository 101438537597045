import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Section from "../components/Interface/Section"
import { Form, FormGroup, Label, Input } from "reactstrap"

// TODO
// Below userData is an example of possible JSON data returned from graphql query
// But it is NOT being used by the form below (yet)

// const userData = {
//   data: {
//     user: {
//       ACFUserData: {
//         firstName: "Craig",
//         sewingMachine: "Brother Luminaire",
//       },
//     },
//   },
// }

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = useState(() => {
    if (typeof window !== "undefined") {
      return localStorage.getItem(localStorageKey) || ""
    } else {
      return '';
    }
  })

  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.setItem(localStorageKey, value) // MAYBE REPLACE value WITH ARRAY
    }
  })

  return [value, setValue]
}

const UserForm = () => {
  const [zipCode, setZipCode] = useStateWithLocalStorage("zipCode")
  // const [distanceFromStore, setDistanceFromStore] = useStateWithLocalStorage(
  //   "distance from store"
  // )
  // const [shippingZone, setShippingZone] = useStateWithLocalStorage(
  //   "shippingZone"
  // )

  const onChangeZipCode = event => {
    setZipCode(event.target.value)
  }

  // const onChangeShippingZone = event => setShippingZone(event.target.value)

  const zip = zipCode.substring(0, 3)

  const z = parseInt(zip)

  function range(z) {
    if ((z >= 800 && z <= 807) || (z >= 811 && z <= 812)) {
      return "1"
    } else if ((z >= 808 && z <= 810) || (z >= 822 && z <= 823) || z === 820) {
      return "2"
    } else if (
      (z >= 690 && z <= 693) ||
      (z >= 814 && z <= 816) ||
      (z >= 824 && z <= 828) ||
      z === 577 ||
      z === 677
    ) {
      return "3"
    } else if (
      (z >= 500 && z <= 503) ||
      (z >= 508 && z <= 516) ||
      (z >= 570 && z <= 576) ||
      (z >= 585 && z <= 588) ||
      (z >= 590 && z <= 593) ||
      (z >= 640 && z <= 641) ||
      (z >= 644 && z <= 647) ||
      (z >= 660 && z <= 662) ||
      (z >= 664 && z <= 676) ||
      (z >= 678 && z <= 681) ||
      (z >= 683 && z <= 689) ||
      (z >= 730 && z <= 731) ||
      (z >= 734 && z <= 741) ||
      (z >= 743 && z <= 749) ||
      (z >= 768 && z <= 769) ||
      (z >= 790 && z <= 799) ||
      (z >= 829 && z <= 832) ||
      (z >= 840 && z <= 847) ||
      (z >= 850 && z <= 853) ||
      (z >= 855 && z <= 857) ||
      (z >= 859 && z <= 860) ||
      (z >= 870 && z <= 871) ||
      (z >= 873 && z <= 885) ||
      z === 505 ||
      z === 525 ||
      z === 597 ||
      z === 649 ||
      z === 813 ||
      z === 821 ||
      z === 834 ||
      z === 863 ||
      z === 865 ||
      z === 898
    ) {
      return "4"
    } else if (
      (z >= 369 && z <= 372) ||
      (z >= 380 && z <= 393) ||
      (z >= 396 && z <= 397) ||
      (z >= 420 && z <= 424) ||
      (z >= 460 && z <= 466) ||
      (z >= 474 && z <= 479) ||
      (z >= 490 && z <= 491) ||
      (z >= 493 && z <= 495) ||
      (z >= 498 && z <= 499) ||
      (z >= 506 && z <= 507) ||
      (z >= 520 && z <= 524) ||
      (z >= 526 && z <= 528) ||
      (z >= 530 && z <= 532) ||
      (z >= 534 && z <= 535) ||
      (z >= 537 && z <= 551) ||
      (z >= 553 && z <= 567) ||
      (z >= 580 && z <= 584) ||
      (z >= 594 && z <= 596) ||
      (z >= 598 && z <= 620) ||
      (z >= 622 && z <= 631) ||
      (z >= 633 && z <= 639) ||
      (z >= 650 && z <= 658) ||
      (z >= 705 && z <= 708) ||
      (z >= 710 && z <= 714) ||
      (z >= 716 && z <= 729) ||
      (z >= 750 && z <= 767) ||
      (z >= 772 && z <= 789) ||
      (z >= 835 && z <= 838) ||
      (z >= 889 && z <= 891) ||
      (z >= 893 && z <= 895) ||
      (z >= 900 && z <= 908) ||
      (z >= 910 && z <= 928) ||
      (z >= 930 && z <= 954) ||
      (z >= 956 && z <= 961) ||
      (z >= 962 && z <= 966) ||
      (z >= 970 && z <= 979) ||
      (z >= 983 && z <= 986) ||
      (z >= 988 && z <= 994) ||
      z === 375 ||
      z === 469 ||
      z === 472 ||
      z === 504 ||
      z === 648 ||
      z === 733 ||
      z === 770 ||
      z === 833 ||
      z === 864 ||
      z === 897
    ) {
      return "5"
    } else if (
      (z >= 140 && z <= 168) ||
      (z >= 240 && z <= 243) ||
      (z >= 245 && z <= 253) ||
      (z >= 255 && z <= 266) ||
      (z >= 270 && z <= 274) ||
      (z >= 280 && z <= 282) ||
      (z >= 286 && z <= 293) ||
      (z >= 295 && z <= 298) ||
      (z >= 300 && z <= 312) ||
      (z >= 316 && z <= 319) ||
      (z >= 323 && z <= 325) ||
      (z >= 350 && z <= 352) ||
      (z >= 354 && z <= 368) ||
      (z >= 373 && z <= 374) ||
      (z >= 376 && z <= 379) ||
      (z >= 394 && z <= 395) ||
      (z >= 398 && z <= 418) ||
      (z >= 425 && z <= 427) ||
      (z >= 430 && z <= 459) ||
      (z >= 467 && z <= 468) ||
      (z >= 470 && z <= 471) ||
      (z >= 480 && z <= 489) ||
      (z >= 496 && z <= 497) ||
      (z >= 700 && z <= 701) ||
      (z >= 703 && z <= 704) ||
      (z >= 980 && z <= 982) ||
      z === 473 ||
      z === 492 ||
      z === 955
    ) {
      return "6"
    } else if (
      (z >= 10 && z <= 43) ||
      (z >= 50 && z <= 89) ||
      (z >= 90 && z <= 99) ||
      (z >= 100 && z <= 139) ||
      (z >= 169 && z <= 212) ||
      (z >= 214 && z <= 239) ||
      (z >= 267 && z <= 268) ||
      (z >= 275 && z <= 279) ||
      (z >= 283 && z <= 285) ||
      (z >= 313 && z <= 315) ||
      (z >= 320 && z <= 322) ||
      (z >= 326 && z <= 342) ||
      (z >= 346 && z <= 347) ||
      z === 5 ||
      z === 45 ||
      z === 48 ||
      z === 244 ||
      z === 254 ||
      z === 294 ||
      z === 299 ||
      z === 344 ||
      z === 349 ||
      z === 999
    ) {
      return "7"
    } else if (
      (z >= 6 && z <= 9) ||
      (z >= 46 && z <= 47) ||
      (z >= 967 && z <= 968) ||
      (z >= 995 && z <= 998) ||
      z === 44 ||
      z === 49
    ) {
      return "8"
    } else if (z === 969) {
      return "9"
    } else {
      return "Your zip code wasn't found. Did you enter it correctly?"
    }
  }

  return (
    <div>
      <div id="userDataForm">
        <h1>Shipping Zone Calulator</h1>
        <Form>
          <FormGroup>
            <Label>Zip Code</Label>
            <Input
              value={zipCode}
              type="text"
              onChange={onChangeZipCode}
              id="zipCodeValue"
            />
            <p>Current Zip Code is {zipCode}</p>
          </FormGroup>
        </Form>
        <h4>Zone set by const "z" is {range(z)} </h4>
        <p>
          The last 2, and the last 1 in particular, can be used to match the
          shipping zone used by Fedex to determine the rate. Each product will
          have hidden array values that match the shipping amount. The shipping
          module will take the zone key - in this case Zone 2 - and match it to
          the Zone 2 value.
        </p>
      </div>
    </div>
  )
}

const Users = () => (
  <Layout location="users">
    <Section>
      <div>
        <UserForm />
      </div>
    </Section>
  </Layout>
)

export default Users
